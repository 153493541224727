import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import builds from "./modules/builds";
import { createStore } from "vuex-extensions";
Vue.use(Vuex);

// export default new Vuex.Store({
export default createStore(Vuex.Store, {
  modules: {
    auth,
    builds,
  },
});

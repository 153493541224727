<template>
  <v-footer
    app
    padless
    fixed
    height="48"
    color="#003345"
    v-if="$vuetify.breakpoint.xs"
  >
    <v-row no-gutters>
      <v-col v-for="item in items" :key="item.title" align="center">
        <v-btn class="mx-4" dark icon router :to="item.link">
          <custom-icon :label="item.title" :icon="item.icon" />
        </v-btn>
      </v-col>
    </v-row>
  </v-footer>
  <v-navigation-drawer
    v-else
    v-model="drawer"
    :mini-variant="drawerState"
    permanent
    clipped
    app
    width="200"
    mini-variant-width="64"
    touchless
    dark
    color="#003345"
  >
    <v-list dense link class="navmenu_list">
      <v-list-item
        v-for="item in items"
        :key="item.title"
        router
        :to="item.link"
        class="mb-6"
        :ripple="false"
      >
        <v-list-item-icon>
          <v-icon> {{ item.icon }}</v-icon>
          <!-- <custom-icon :label="item.title" :icon="item.icon" /> -->
        </v-list-item-icon>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {
      drawer: true,
      mini: false,
      items: [
        {
          title: "Dashboard",
          icon: "mdi-home-city-outline",
          link: "/dashboard",
        },
        // { title: "Settings", icon: "mdi-cog-outline", link: "/settings" },
      ],
      right: null,
    };
  },
  computed: {
    drawerState: {
      get() {
        return this.$store.getters["auth/drawerState"];
      },
      set(v) {
        return this.$store.commit("auth/toggleDrawerState", v);
      },
    },
    hasAdminAccess() {
      return this.$store.getters["auth/checkAdminAccess"];
    },
    iconMenu() {
      if (this.mini) {
        return "mdi-menu";
      }
      return "mdi-menu-open";
    },
  },
  methods: {},
};
</script>

<style>
/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}
.navmenu_list .v-list-item {
  text-decoration: none;
}
.navmenu_list .v-list-item--active {
  color: #60b7d9;
}

.myClass:focus::before {
  opacity: 0 !important;
}
.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before {
  content: "";
  flex: 1;
  border-bottom: 1px solid rgb(141, 141, 141);
  width: 10px;
}
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid rgb(141, 141, 141);
}

.separator:not(:empty)::before {
  margin-right: 0.25em;
}

.separator:not(:empty)::after {
  margin-left: 0.25em;
}

.v-list-item--link:before {
  background-color: transparent;
}
</style>

import { api } from "@/api";

export default {
  namespaced: true,
  state: {
    projects: [],
    builds: [],
    installerVersion: "",
  },
  getters: {
    getProjects: (state) => {
      return state.projects;
    },
    getBuilds: (state) => {
      return state.builds;
    },
    getBuildInfo: (state) => (build_id) => {
      return state.builds.find((build) => build.uid == build_id);
    },
    getInstallerVersion: (state) => {
      return state.installerVersion;
    },
  },
  mutations: {
    setProjects: (state, payload) => {
      state.projects = payload;
    },
    setBuilds: (state, payload) => {
      state.builds = payload;
    },
    setInstallerVersion: (state, payload) => {
      state.installerVersion = payload;
    },
  },
  actions: {
    async actionGetProjects(context) {
      try {
        const response = await api.getProjects(context.rootState.auth.token);
        if (response) {
          context.commit("setProjects", response.data);
        }
      } catch (error) {
        //await dispatchCheckApiError(context, error);
      }
    },
    async actionGetBuilds(context) {
      try {
        const response = await api.getBuilds(context.rootState.auth.token);
        if (response) {
          context.commit("setBuilds", response.data);
        }
      } catch (error) {
        //await dispatchCheckApiError(context, error);
      }
    },
    async actionUploadBuild(context, payload) {
      try {
        const response = await api.uploadBuild(
          context.rootState.auth.token,
          payload.formData
        );
        if (response) {
          await context.dispatch("actionGetBuilds", { root: true });
          await context.dispatch("actionGetProjects", { root: true });
        }
      } catch (error) {
        //
      }
    },
    async actionUploadInstaller(context, payload) {
      try {
        const response = await api.uploadInstallerMsix(
          context.rootState.auth.token,
          payload.formData
        );
        if (response) {
          await context.dispatch("actionGetInstallerVersion", { root: true });
        }
      } catch (error) {
        //
      }
    },
    async actionGetInstallerVersion(context) {
      try {
        const response = await api.getInstallerVersion();
        if (response) {
          context.commit("setInstallerVersion", response.data);
        }
      } catch (error) {
        //await dispatchCheckApiError(context, error);
      }
    },
  },
};

// eslint-disable-next-line no-unused-vars
import axios from "axios";
// eslint-disable-next-line no-unused-vars

const apiUrl = String(process.env.VUE_APP_BACKEND_URL) + "/api/";

function authHeaders(token) {
  return {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
}

export const api = {
  async logInGetToken(username, password) {
    const params = new URLSearchParams();
    params.append("username", username);
    params.append("password", password);
    return axios.post(`${apiUrl}login/access-token`, params);
  },
  async getBuildToken(token) {
    return axios.post(`${apiUrl}build-token`, null, authHeaders(token));
  },
  async getDownloadToken(token, payload) {
    return axios.post(`${apiUrl}download-token`, payload, authHeaders(token));
  },
  logout() {
    localStorage.removeItem("user");
  },
  async getMe(token) {
    return axios.get(`${apiUrl}users/me/`, authHeaders(token));
  },
  async getUsers(token) {
    return axios.get(`${apiUrl}users/`, authHeaders(token));
  },
  async getUsernames(token) {
    return axios.get(`${apiUrl}users/usernames/`, authHeaders(token));
  },
  async createUser(token, payload) {
    return axios.post(`${apiUrl}users/`, payload, authHeaders(token));
  },
  async updateUser(token, payload) {
    return axios.put(
      `${apiUrl}users/${payload.id}`,
      payload,
      authHeaders(token)
    );
  },
  async deleteUser(token, user_id) {
    return axios.delete(`${apiUrl}users/${user_id}`, authHeaders(token));
  },
  // Projects API
  async getProjects(token) {
    return axios.get(`${apiUrl}builds/projects`, authHeaders(token));
  },
  // BUILDS API
  async getBuilds(token) {
    return axios.get(`${apiUrl}builds/admin`, authHeaders(token));
  },
  async uploadBuild(token, payload) {
    return axios.post(`${apiUrl}builds/upload`, payload, authHeaders(token));
  },
  // INSTALLER API
  async uploadInstallerMsix(token, payload) {
    return axios.post(
      `${apiUrl}builds/installer/msix`,
      payload,
      authHeaders(token)
    );
  },
  async getInstallerVersion() {
    return axios.get(`${apiUrl}builds/installer/version`);
  },
};

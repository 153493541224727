import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () =>
      import(/* webpackChunkName: "start" */ "../views/StartView.vue"),
    children: [
      {
        path: "login",
        component: () =>
          import(/* webpackChunkName: "login" */ "../views/LoginView.vue"),
      },
      {
        path: "logout",
        component: () =>
          import(/* webpackChunkName: "logout" */ "../views/LogoutView.vue"),
      },
      {
        path: "dashboard",
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/HomeView.vue"),
      },
    ],
  },
  // {
  //   path: "/",
  //   name: "home",
  //   component: HomeView,
  // },

  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;

import { api } from "@/api";
import { removeLocalToken, saveLocalToken, getLocalToken } from "@/utils";
import router from "@/router";

export default {
  namespaced: true,
  state: {
    userProfile: {
      id: "",
      username: "",
      email: "",
      is_active: "",
      is_superuser: "",
      is_admin: "",
    },
    isLoggedIn: false,
    activeProject: null,
    token: "",
    tokenExp: "",
    logInError: false,
    logInErrorMessage: "",
    drawerState: true,
  },
  getters: {
    isLoggedIn: (state) => {
      return state.isLoggedIn;
    },
    checkAdminAccess: (state) => {
      return state.userProfile.is_admin;
    },
    token: (state) => {
      return state.token;
    },
    // hasAdminAccess: (state) => {
    //   return (
    //     state.userProfile &&
    //     state.userProfile.isAdmin &&
    //     state.userProfile.isActive
    //   );
    // },
    userProfile: (state) => {
      return state.userProfile;
    },
    logInError: (state) => {
      return state.logInError;
    },
    logInErrorMessage: (state) => {
      return state.logInErrorMessage;
    },
    drawerState: (state) => state.drawerState,
  },
  mutations: {
    toggleDrawerState: (state) => {
      state.drawerState = !state.drawerState;
    },
    setLoggedIn: (state, payload) => {
      state.isLoggedIn = payload;
    },
    setToken: (state, payload) => {
      state.token = payload;
    },
    setLogInError: (state, payload) => {
      state.logInError = payload;
    },
    setLogInErrorMessage: (state, payload) => {
      state.logInErrorMessage = payload;
    },
    setUserProfile: (state, payload) => {
      state.userProfile = payload;
    },
  },
  actions: {
    async actionLogIn(context, payload) {
      try {
        const response = await api.logInGetToken(
          payload.username,
          payload.password
        );
        const token = response.data.access_token;
        if (token) {
          saveLocalToken(token); //saving token to localstorage
          context.commit("setToken", token);
          context.commit("setLoggedIn", true);
          context.commit("setLogInError", false);
          await context.dispatch("actionRouteLoggedIn");
        } else {
          await context.dispatch("actionLogOut");
        }
      } catch (err) {
        context.commit("setLogInError", true);
        context.commit("setLogInErrorMessage", err.response.data.detail);
        await context.dispatch("actionLogOut");
      }
    },
    async actionGetBuildToken(context) {
      try {
        const response = await api.getBuildToken(context.state.token);
        const token = response.data.access_token;
        if (token) {
          return token;
        }
      } catch (err) {
        //
      }
    },
    async actionGetDownloadToken(context, payload) {
      try {
        const response = await api.getDownloadToken(
          context.state.token,
          payload.formData
        );
        const token = response.data.access_token;
        if (token) {
          return token;
        }
      } catch (err) {
        //
      }
    },
    actionRouteLoggedIn() {
      if (
        router.currentRoute.path === "/login" ||
        router.currentRoute.path === "/"
      ) {
        router.push("/dashboard");
      }
    },
    actionRouteLogOut() {
      if (router.currentRoute.path !== "/login") {
        router.push("/login");
      }
    },
    async actionLogOut(context) {
      await context.dispatch("actionRemoveLogIn");
      await context.dispatch("actionRouteLogOut");
    },
    async actionRemoveLogIn(context) {
      removeLocalToken();
      context.commit("setToken", "");
      context.commit("setLoggedIn", false);
    },

    async actionCheckLoggedIn(context) {
      if (!context.state.isLoggedIn) {
        let token = context.state.token;
        if (!token) {
          const localToken = getLocalToken();
          if (localToken) {
            context.commit("setToken", localToken);
            token = localToken;
          }
        }
        if (token) {
          try {
            const response = await api.getMe(token);
            context.commit("setLoggedIn", true);

            // context.commit('setUserProfile', response.data);
          } catch (error) {
            await context.dispatch("actionRemoveLogIn");
          }
        } else {
          await context.dispatch("actionRemoveLogIn");
        }
      }
    },
    async actionCheckApiError(context, payload) {
      if (payload.response.status === 401 || payload.response.status === 403) {
        await context.dispatch("actionLogOut");
      }
    },
    // async actionCheckTokenExp(context) {
    //   const tokenExp = context.state.tokenExp;
    //   const timeLeft = parseInt(tokenExp - new Date() / 1000);
    //   console.log(tokenExp);
    //   console.log(timeLeft);
    //   if (timeLeft < 0) {
    //     console.log("token no longer valid");
    //     await context.dispatch("actionRemoveLogIn");
    //   }
    // },
  },
};

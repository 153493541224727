<template>
  <v-app-bar app dark clipped-left clipped-right flat color="#002938">
    <router-link to="/dashboard">
      <v-img
        v-if="!$vuetify.breakpoint.xs"
        src="@/assets/logo_imi2.svg"
        contain
        max-height="56"
        height="56"
        width="300"
      >
      </v-img>
      <v-row v-else>
        <v-img
          src="@/assets/icon_imi.svg"
          contain
          max-height="56"
          height="56"
          width="40"
          class="ml-2"
        >
        </v-img>
        <v-img
          src="@/assets/text_imi.svg"
          contain
          max-height="56"
          height="56"
          width="96"
          class="ml-2"
        >
        </v-img>
      </v-row>
    </router-link>

    <v-spacer></v-spacer>
    <!-- <router-link to="/installer" style="text-decoration: none; color: inherit">
        </router-link> -->
    <v-btn text @click="goToInstaller()">
      {{ installerVersion }}
      <v-icon class="ml-4">mdi-rocket-launch-outline</v-icon>
    </v-btn>

    <v-icon @click="logout" class="ml-4">mdi-logout</v-icon>
  </v-app-bar>
</template>

<script>
//import moment from "moment";

export default {
  data() {
    return {
      drawer: true,
      selectedProject: null,
      title: "",
      items: [{ title: "Dashboard", icon: "mdi-view-dashboard" }],

      right: null,
    };
  },
  created() {
    this.$store.dispatch("builds/actionGetInstallerVersion");
  },
  computed: {
    installerVersion() {
      return this.$store.getters["builds/getInstallerVersion"];
    },
    hasAdminAccess() {
      return this.$store.getters["auth/checkAdminAccess"];
    },
    projects() {
      return this.$store.getters["auth/userProjects"];
    },
    username() {
      return this.$store.getters["auth/userProfile"].username.toUpperCase();
    },
    userRole() {
      var profile = this.$store.getters["auth/userProfile"];
      var role = "user";
      if (profile.is_superuser) {
        role = "superuser";
      }
      if (profile.is_admin) {
        role = "administrator";
      }

      return role;
    },
    activeProject() {
      console.log("ROUTE", this.$route.name);
      if (this.$route.name != "ProjectDashboard") {
        this.$store.commit("auth/setActiveProject", null);
      }
      return this.$store.getters["auth/activeProject"];
    },
    pageTitle() {
      return this.$route.meta.pageTitle;
    },
    navIcon() {
      return this.$store.getters["auth/drawerState"]
        ? "mdi-forwardburger"
        : "mdi-backburger";
    },
  },
  methods: {
    goToInstaller() {
      location.href = "https://builds.imi-lab.io/installer";
    },
    getInitials(username) {
      return username.slice(0, 1).toUpperCase();
    },
    logout: function () {
      this.$store.dispatch("auth/actionLogOut");
    },
    getEnvironment() {
      if (this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm) {
        return String(process.env.VUE_APP_ENVIRONMENT)
          .toUpperCase()
          .substring(0, 3);
      } else {
        return String(process.env.VUE_APP_ENVIRONMENT).toUpperCase();
      }
    },
  },
};
</script>
<style scoped>
.v-input__control {
  background: #5b3648;
}

.custom {
  max-width: 150px;
}

.v-list-item {
  text-decoration: none;
}

.v-btn {
  outline: none;
}
</style>

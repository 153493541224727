<template>
  <v-app id="app" style="background: #f5f5f5">
    <vtoast ref="vtoast" />
    <NavBar v-if="loggedIn === true" />
    <SideBar v-if="loggedIn === true" />
    <v-container fill-height v-if="loggedIn === null">
      <v-layout fill-height align-center justify-center>
        <v-flex>
          <div class="text-xs-center">
            <div class="headline my-5">Loading...</div>
            <v-progress-circular
              size="100"
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
    <v-main v-else>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import NavBar from "@/components/NavBar";
import SideBar from "@/components/SideBar";
import axios from "axios";
import store from "@/store";
import vtoast from "@/components/vtoast";

export default {
  name: "App",
  components: {
    NavBar,
    SideBar,
    vtoast,
  },

  data: () => ({
    //
  }),
  computed: {
    loggedIn() {
      return this.$store.getters["auth/isLoggedIn"];
    },
  },
  mounted() {
    this.$root.vtoast = this.$refs.vtoast;
  },
  created() {
    this.$store.dispatch("auth/actionCheckLoggedIn");
    axios.interceptors.response.use(
      async function (response) {
        return response;
      },
      async function (error) {
        if (error.response.status === 401) {
          store.dispatch("auth/actionLogOut");
        }
        return Promise.reject(error);
      }
    );
  },
};
</script>
